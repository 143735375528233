<template>
  <div class="px-8">
    <div
      class="h-full p-[24px]"
      :style="blockStyle"
    >
      <div class="flex items-center">
        <img
          v-if="options.show?.image"
          class="h-[72px] w-[72px] rounded-full object-cover ltr:mr-8 rtl:ml-8"
          :src="image"
        />
        <div class="flex-1">
          <div
            class="break-words font-semibold ltr:text-left rtl:text-right"
            style="word-break: break-word;"
          >
            {{ title }}
          </div>
          <div v-if="options.show?.rate" class="flex items-center">
            <Start
              v-for="index of 5"
              :key="index"
              color="#F3CD02"
            />
          </div>
        </div>
      </div>
      <div v-if="desc" class="break-words last:mt-16">
        {{ desc }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type PropType, computed } from 'vue';
import type { PageOptions, Element as ElementType } from '@shared/types/model';
import type { ElementOptions } from '@shared/types/options';
import Start from './star.vue';

const props = defineProps({
  title: { type: String, default: '' },
  desc: { type: String, default: '' },
  image: { type: String, default: '' },
  style: { type: Object as PropType<ElementType<'testimonials'>['style']>, default: () => ({}) },
  options: { type: Object as PropType<ElementOptions['testimonials']>, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) }
});

const blockStyle = computed(() => {
  return {
    color: props.style.color || props.pageOptions.colors.text[0],
    borderColor: props.options.border?.color || '',
    borderWidth: `${props.options.border?.width || 0}px`,
    borderRadius: `${props.options.border?.radius || props.pageOptions.borderRadius}px`,
    backgroundColor: props.options.bgColor || undefined,
    fontFamily: props.style.fontFamily || props.pageOptions?.fontFamily
  }
});
</script>
